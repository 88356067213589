import { Button, Input, Select, SelectItem } from "@nextui-org/react";
import React, { useState } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import moment from "moment";

const ExportCSV = ({ data }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [otpStatus, setOtpStatus] = useState("all");

  const normalizeDateToUTC = (dateString) => {
    // Convert date string to UTC using moment
    return moment.utc(dateString).toDate();
  };

  const exportToExcel = () => {
    let dataToExport = data;

    // Filter by date range with UTC normalization using moment
    if (startDate && endDate) {
      const start = normalizeDateToUTC(startDate);
      const end = normalizeDateToUTC(endDate);

      dataToExport = dataToExport.filter((member) => {
        const memberDate = normalizeDateToUTC(member.date);
        return moment(memberDate).isBetween(start, end, undefined, '[]');
      });
    }

    // Filter by OTP status
    if (otpStatus !== "all") {
      dataToExport = dataToExport.filter(
        (member) => member.otpVerified === (otpStatus === "verified")
      );
    }

    if (dataToExport.length === 0) {
      alert("No data matches the selected criteria.");
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });

    saveAs(blob, "all_members.xlsx");
  };

  return (
    <div className="flex flex-wrap sm:flex-nowrap justify-start gap-12 items-center mt-4">
      <Button
        className="bg-themeSecondry-0 text-white w-full"
        onClick={exportToExcel}
      >
        Export as Excel
      </Button>

      <Select
        label="OTP Verification Status"
        value={otpStatus}
        onChange={(e) => setOtpStatus(e.target.value)}
      >
        <SelectItem key="all" value="all">
          All
        </SelectItem>
        <SelectItem key="verified" value="verified">
          Verified
        </SelectItem>
        <SelectItem key="not_verified" value="not_verified">
          Not Verified
        </SelectItem>
      </Select>

      <Input
        type="date"
        label="Start Date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
      />
      <Input
        type="date"
        label="End Date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
      />
    </div>
  );
};

export default ExportCSV;
