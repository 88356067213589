import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { API } from "../../api";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const GraphsComponent = () => {
  const [selectedRange, setSelectedRange] = useState("daily");
  const [chartData, setChartData] = useState({});
  const [graphData, setGraphData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await API.getStatisticsGraph(selectedRange);
      setGraphData(response?.data?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching graph data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedRange]);

  useEffect(() => {
    const labels = graphData.map((data) => data.name);
    const totalUsersData = graphData.map((data) => data.totalUsers);
    const subscribedData = graphData.map((data) => data.subscribed);
    const unSubscribedData = graphData.map((data) => data.unSubscribed);
    const revenueData = graphData.map((data) => data.revenue);

    setChartData({
      labels,
      datasets: [
        {
          label: "Total Users",
          data: totalUsersData,
          backgroundColor: "rgba(75, 192, 192, 0.6)",
        },
        {
          label: "Subscribed",
          data: subscribedData,
          backgroundColor: "rgba(153, 102, 255, 0.6)",
        },
        {
          label: "Unsubscribed",
          data: unSubscribedData,
          backgroundColor: "rgba(255, 159, 64, 0.6)",
        },
        {
          label: "Revenue",
          data: revenueData,
          backgroundColor: "rgba(255, 99, 132, 0.6)",
        },
      ],
    });
  }, [graphData]);

  return (
    !loading && (
      <div className="graph-container">
        <h3 className="mt-5">User Distribution</h3>

        <div className="flex justify-end w-full">
          <select
            value={selectedRange}
            onChange={(e) => setSelectedRange(e.target.value)}
            className="max-w-[300px] w-full py-3 rounded-[12px] px-3 pr-10 border focus:border-themePrimary-0 active:border-themePrimary-0 outline-none appearance-none bg-[url('data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27 fill=%27gray%27%3E%3Cpath fill-rule=%27evenodd%27 d=%27M6 8l4 4 4-4%27 clip-rule=%27evenodd%27/%3E%3C/svg%3E')] bg-no-repeat bg-right"
            style={{
              backgroundPositionX: "calc(100% - 8px)", 
              backgroundPositionY: "center",
            }}
          >
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
          </select>
        </div>

        <Bar
          data={chartData}
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: "bottom",
              },
            },
          }}
        />
      </div>
    )
  );
};

export default GraphsComponent;
