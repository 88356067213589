import React, { useEffect, useState } from "react";
import Header from "../../../components/dashboard/Header";
import ResultFilterBar from "../../../components/general/ResultFilterBar";
import Loader from "../../../components/general/Loader";
import Tableform from "../../../components/general/Tableform";
import { API } from "../../../api";
import { errorToast } from "../../../hooks/useToast";
import { questioncolumns } from "../../../data/questionscolumn";

const QuestionsComponent = () => {
  const [itemPerPage, setitemPerPage] = useState(10);
  const [questionStatistics, setQuestionsStatistics] = useState();
  const [searchFilter, setSearchFilter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [alltopics, setAllTopics] = useState(null);

  const getData = async () => {
    try {
      let response = await API.getAllQuestion();
      setLoading(false);
      setAllTopics(response?.data?.data);
      response = await API.getQuestionStatistics();
      setQuestionsStatistics(response?.data?.data);
    } catch (error) {
      setLoading(false);
      errorToast(error, "Can not fetch data");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const renderStatistics = () => {
    if (!questionStatistics) return null;

    const { categories, mode, totalQuestions } = questionStatistics;

    return (
      <div className="bg-gradient-to-br from-indigo-100 to-purple-100 px-4 py-2 rounded-lg max-w-full mx-auto mt-10">
        <div className="space-y-6">
          <div className="bg-white max-w-[250px] mx-auto mt-3 bg-opacity-60 rounded-lg py-3 px-5 shadow-sm flex justify-between items-center">
            <span className="text-purple-700 font-semibold">
              Total Questions
            </span>
            <span className="text-purple-700 font-semibold">
              {totalQuestions?._count?.id}
            </span>
          </div>
          <StatisticsSection
            title="Categories"
            data={categories}
            keyProp="categoryId"
            nameProp="categoryName"
          />
          <StatisticsSection
            title="Difficulty Mode"
            data={mode}
            keyProp="difficultyMode"
            nameProp="difficultyMode"
          />
        </div>
      </div>
    );
  };

  const StatisticsSection = ({ title, data, keyProp, nameProp }) => (
    <div>
      <h3 className="text-xl font-semibold text-purple-800 mb-4">{title}</h3>
      <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 gap-4">
        {data.map((item) => (
          <div
            key={item[keyProp]}
            onClick={() => setSearchFilter(item[nameProp])}
            className={`  rounded-lg py-1 px-2 shadow-sm flex justify-between items-center cursor-pointer ${
              searchFilter === item[nameProp]
                ? `bg-themeSecondry-0 text-white`
                : `bg-white bg-opacity-60 text-indigo-700`
            }`}
          >
            <span className="text-sm ">{item[nameProp]}</span>
            <span
              className={` font-semibold ${
                searchFilter === item[nameProp]
                  ? `text-white`
                  : `text-purple-700`
              }`}
            >
              {item.count}
            </span>
          </div>
        ))}
      </div>
    </div>
  );

 

  return (
    <div className="page-area mt-10">
      <Header
        pagetitle={"Questions"}
        previous={"Dashboard"}
        currentpage={"topics"}
        btntext={"Add Questions"}
        btnlink={"/dashboard/exam/questions/add-question"}
      />
      <div className="page-comp bg-white mt-10 rounded-xl px-8 py-8">
        <ResultFilterBar
          setitemPerPage={setitemPerPage}
          setfilterdata={setAllTopics}
          filterdata={alltopics}
          setSearchFilter={setSearchFilter}
        />
        {loading ? (
          <Loader />
        ) : (
          <>
            {renderStatistics()}
            <Tableform
              searchName={true}
              filterdata={alltopics}
              tablecolumns={questioncolumns}
              itemPerPage={itemPerPage}
              searchFilter={searchFilter}
              pagename={"edit-question"}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default QuestionsComponent;
