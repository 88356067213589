import Image from "@tiptap/extension-image";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
// import './Image.css'

function ImageNode(props) {
  const { src, alt } = props.node.attrs;

  let className = "image";
  if (props.selected) {
    className += " ProseMirror-selectednode";
  }

  const { updateAttributes } = props;

  const onEditAlt = () => {
    const newAlt = prompt("Set alt text:", alt || "");
    updateAttributes({ alt: newAlt });
  };

  return (
    <NodeViewWrapper className={className} data-drag-handle>
      <div
        className={`${className} relative group overflow-hidden rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300`}
      >
        <img
          src={src}
          alt={alt}
          className="w-full h-auto object-cover transition-transform duration-300 group-hover:scale-105"
        />
        <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/70 to-transparent p-4 transform translate-y-full group-hover:translate-y-0 transition-transform duration-300">
          {alt && (
            <span className="block text-white font-semibold mb-2 truncate">
              Alt text: {alt}
            </span>
          )}
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
            type="button"
            onClick={onEditAlt}
          >
            Edit
          </button>
        </div>
      </div>
    </NodeViewWrapper>
  );
}

export default Image.extend({
  addNodeView() {
    return ReactNodeViewRenderer(ImageNode);
  },
});
