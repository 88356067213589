import React, { useEffect, useState } from "react";
import Header from "../../../components/dashboard/Header";
import InputField from "../../../components/general/InputField";
import ButtonComponent from "../../../components/general/ButtonComponent";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { API } from "../../../api";
import { useNavigate } from "react-router-dom";
import { errorToast, successToast } from "../../../hooks/useToast";
import { addTopicSchema } from "../../../validations/topics";

const AddTopic = () => {
  const [isInvalid, setIsInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(addTopicSchema) });

  const [allexams, setAllExams] = useState([]);

  const getExamData = async () => {
    try {
      const response = await API.getAllExams();
      setAllExams(response?.data?.data);
    } catch (error) {
      errorToast(error, "Cannot fetch exams");
    }
  };

  useEffect(() => {
    getExamData();
  }, []);

  const [allcategory, setAllCategory] = useState([]);

  const getCategoryData = async () => {
    try {
      const response = await API.getAllCategories();
      setAllCategory(response?.data?.data);
    } catch (error) {
      errorToast(error, "Cannot fetch exams");
    }
  };

  useEffect(() => {
    getCategoryData();
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await API.addTopics(data);
      successToast(response?.data?.message);
      setLoading(false);
      // navigate(-1);
    } catch (error) {
      setLoading(false);
      errorToast(error, "Cannot add Topic");
    }
  };

  const handleCancle = () => {
    navigate(-1);
  };
  return (
    <div className="page-area mt-10">
      <Header
        pagetitle={"Topics"}
        previous={"Dashboard"}
        currentpage={"Add Topic"}
      />
      <form className="grid grid-col-1 gap-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="page-comp bg-white mt-10 rounded-xl px-8 py-8">
          <InputField
            label="Topic Name"
            type="text"
            isInvalid={isInvalid}
            isRequired={true}
            placeholder="Topic Name"
            errortext="Topic Name Is Required"
            errors={errors}
            name="name"
            register={register}
          />
          <div className="grid grid-col-1 sm:grid-cols-3 gap-4  ">
            <InputField
              label="Exam Name"
              type="select"
              options={allexams}
              isInvalid={isInvalid}
              isRequired={true}
              placeholder="Exam Name"
              errortext="Exam Name Is Required"
              errors={errors}
              name="examId"
              register={register}
            />
            <InputField
              label="Category Name"
              type="select"
              options={allcategory}
              isInvalid={isInvalid}
              isRequired={true}
              placeholder="Category Name"
              errortext="Category Name Is Required"
              errors={errors}
              name="categoryId"
              register={register}
            />
            <InputField
              label="Status"
              type="select"
              options={[true, false]}
              isInvalid={isInvalid}
              placeholder="Status"
              isRequired={true}
              errortext="Status Is Required"
              errors={errors}
              name="isLive"
              register={register}
            />
          </div>

          <div className="w-full md:w-1/4 mt-4">
            <div className="flex gap-3">
              <ButtonComponent
                type="submit"
                text="Save"
                loading={loading}
                isActive={true}
              />
              <ButtonComponent
                text="Cancel"
                isActive={true}
                btnclass={"bg-red-500"}
                onClick={() => handleCancle()}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddTopic;
