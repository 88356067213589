import axios from "axios";
import Cookies from "js-cookie";

const BASE_URL = process.env.REACT_APP_BASE_URL_SERVER;

const API = axios.create({
  baseURL: BASE_URL,
});

API.interceptors.request.use(
  function (config) {
    let token;
    if (Cookies.get("token")) {
      token = Cookies.get("token");
    }
    config.headers.authorization = `${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

//adminlogin
API.adminLogin = (data) => {
  return API.post("admin/login", data);
};

API.changePassword = (data) => {
  return API.patch("admin/change-password", data);
};

API.updateProfile = (id, data) => {
  return API.patch(`admin/${id}`, data);
};

// exam
API.getAllExams = () => {
  return API.get("exam");
};
API.updateExam = (id, data) => {
  return API.patch(`exam/${id}`, data);
};

API.addExam = (data) => {
  return API.post("exam", data);
};

API.getSingleExam = (id) => {
  return API.get(`exam/${id}`);
};

//Category

API.getAllCategories = () => {
  return API.get("category");
};

API.getSingleCategorey = (id, data) => {
  return API.patch(`category/${id}`, data);
};

API.createCategory = (data) => {
  return API.post("category", data);
};

API.updateCategory = (id, data) => {
  return API.patch(`category/${id}`, data);
};

// Promo
API.getAllPromo = () => {
  return API.get("promo");
};
API.addPromo = (data) => {
  return API.post("promo", data);
};
API.updatePromo = (id, data) => {
  return API.patch(`promo/${id}`, data);
};

// blogs

API.getAllBlogs = () => {
  return API.get("blog");
};

API.getSingleBlog = (id) => {
  return API.get(`blog/${id}`);
};

API.createBlogs = (data) => {
  return API.post("blog", data);
};

API.updateBlogs = (id, data) => {
  return API.patch(`blog/${id}`, data);
};

API.deleteBlogs = (id) => {
  return API.delete(`blog/${id}`);
};

//users
API.registerUser = (data) => {
  return API.post("user", data);
};

API.deleteUser = (id) => {
  return API.delete(`user/${id}`);
};

//MembersManagement
API.getAllUsers = () => {
  return API.get("user");
};

API.getSingleUsers = (id) => {
  return API.get(`user/${id}`);
};

API.getAllSubscribedMembers = () => {
  return API.get("user/subscription");
};
API.registerUserSubscription = (data) => {
  return API.post("user/subscription", data);
};
API.getSingleSubscribedMembers = (id) => {
  return API.get(`user/subscription/${id}`);
};
API.updateUserSubscription = (id, data) => {
  return API.patch(`user/subscription/${id}`, data);
};

API.createuser = (data) => {
  return API.post("user/by-admin", data);
};

API.updateUser = (data) => {
  return API.patch(`user/by-admin`, data);
};

//adming
// API.updateUserByAdmin = (data) => {
//   return API.patch(`user`, data);
// };

API.logInUser = (data) => {
  return API.post("user/login", data);
};
API.forgetPassword = (data) => {
  return API.post("user/forget-password", data);
};
API.resetPassword = (data) => {
  return API.post("user/reset-password", data);
};

//subscription
API.getAllSubscription = (data) => {
  return API.get("subscription", data);
};
API.addSubscription = (data) => {
  return API.post("subscription", data);
};
API.updateSubscription = (id, data) => {
  return API.patch(`subscription/${id}`, data);
};

//topics
API.getAllTopics = (data) => {
  return API.get("topic", data);
};

API.getCategoryTopics = (id) => {
  return API.get(`/topic/category/${id}`);
};

API.addTopics = (data) => {
  return API.post("topic", data);
};
API.updateTopics = (id, data) => {
  return API.patch(`topic/${id}`, data);
};

//questions
API.getAllQuestion = (data) => {
  return API.get("question", data);
};
API.getQuestionStatistics = () => {
  return API.get("/question/over-all");
};
API.getSingleQuestion = (id) => {
  return API.get(`question/${id}`);
};
API.addQuestion = (data) => {
  return API.post("question", data);
};
API.updateQuestion = (id, data) => {
  return API.patch(`question/${id}`, data);
};

//questions
API.SubscriptionUser = (data) => {
  return API.get("/user/subscription", data);
};

//all country
API.getAllCountry = (data) => {
  return API.get("/country", data);
};

//all feedback
API.getAllFeedBack = () => {
  return API.get("/feedback");
};

API.updatefeedBackStatus = (id, data) => {
  return API.patch(`feedback/${id}`, data);
};

//all reports
API.getAllReports = () => {
  return API.get("/report");
};

API.updateReportStatus = (id, data) => {
  return API.patch(`report/${id}`, data);
};

//newsletter
API.getAllNewsletter = () => {
  return API.get("/newsletter");
};

API.uploadImage = (data) => {
  return API.post("/upload", data);
};

//statistics

API.getStatistics = (startDate, endDate, choice) => {
  return API.get(
    `/dashboard?startDate=${startDate}&endDate=${endDate}&choice=${choice}`
  );
};

API.getStatisticsGraph = (selectedRange) => {
  return API.get(`/dashboard/graphs?range=${selectedRange}`);
};

export { API };
